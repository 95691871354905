<template>
<v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 class="mt-12">
        <v-card flat>
          <!-- Push Contents In and Put Space Between Flex Items Using Grid List -->
          <v-container fluid grid-list-lg>
            <toolbar-section title="Usuarios"
             placeholderSearch="Buscar por nombre"
              @searchUser="searchUserData"
              @filterItemChanged="filterWasChanged"/>
            <v-layout row wrap v-if="itemsFiltered.length > 0">
              <!--  Create Cards Based on Objects in an Array -->
              <v-flex class="card-item" 
              v-for="user in itemsFiltered" v-bind="{ [`lg4 md4 xl4 sm6`]: true }" 
              :key="user._id"
              >
                <v-card flat @click.native="showUserInfo(user)">
                  <v-list color="cards" rounded>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-badge
                          bottom
                          bordered
                          dot
                          :color="user.status == 0 ? 'error':'green'"
                          offset-x="13"
                          offset-y="13"
                        >
                          <v-avatar size="50">
                            <!-- <img
                          src="https://picsum.photos/1920/1080?random"
                          alt="image"
                        > -->
                            <v-img
                            v-if="user.avatar"
                            :key="user.updatedAt"
                            :src="renderImage(user.avatar)"
                            ></v-img>
                            <img
                              v-else
                              :src="require('@/assets/app/img-default-colaborador.jpg')"
                              alt="image user"
                            />
                          </v-avatar>
                        </v-badge>
                        <!-- <img
                          src="https://picsum.photos/1920/1080?random"
                          alt="image"
                        > -->
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{user.name}}</v-list-item-title>
                        <v-list-item-subtitle>{{user.city}}</v-list-item-subtitle>
                        <v-list-item-subtitle>Membresia: {{user.membershipType.name}}</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-menu bottom
                        origin="center center"
                        transition="scale-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            icon
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              <unicon name="ellipsis-v" fill="#5A5A5A"></unicon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item @click.stop="toggleActivationItem(user)">
                              <v-list-item-title>{{ user.status ? 'Desactivar': 'Activar' }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click.stop="deleteItem(user)">
                              <v-list-item-title>Eliminar</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>

            <no-results v-else />
            <confirm-modal ref="confirm" @interface="componentConfirmResult" />
            <user-info-drawer ref="drawerUser" @toggleSwitch="toggleActivationUserConfirmed"/>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
</template>
<script>
  import axios from 'axios';
  import ToolbarSection from './Toolbars/ToolbarSection'
  import ConfirmModal from './Dialogs/ConfirmModal'
  import UserInfoDrawer from './Drawers/UserInfoDrawer'
  import NoResults from './NoResults'

  const REQUEST_USER_DELETE = 1;
  const REQUEST_USER_TOGGLE_ACTIVATE = 2;
  export default {
    components: {
      ToolbarSection,
      ConfirmModal,
      UserInfoDrawer,
      NoResults
    },
    data: () => ({
      users: [],
      currentSearch: '',
      filterSelected: -1, //Todos los items
      publicPath: process.env.VUE_APP_URL
    }),

    created () {
      this.initialize();
    },

    computed: {
      itemsFiltered(){
        let items = this.users;
        if(this.filterSelected >= 0){
          console.log(this.filterSelected);
          if(this.filterSelected < 2){//Es filtro de status
            items = this.users.filter(item => item.status == this.filterSelected);
          }else{
            items = this.filterSelected == 2 ? this.users.filter(item => item.membershipType.name == 'Free') : 
            this.users.filter(item => item.membershipType.name == 'Premium');
          }
        }
        return items;
      },
    },

    mounted() {
      this.$store.dispatch('savePath', 'users');
      this.$bus.$emit('selectCurrentMenuOption',1);
    },

    methods: {
      renderImage(image){
          const imgPath = this.publicPath+'images/uploads/users/' + image;
          console.log(imgPath);
          return imgPath;
      },
      filterOk(user){
        let show = false;
        if(this.filterSelected < 2){//Es filtro de status
          show = this.filterSelected < 0 || this.filterSelected == user.status;
        }else{
          show = (this.filterSelected == 2 && user.membershipType.name == 'Free') || 
          (this.filterSelected == 3 && user.membershipType.name == 'Premium');
        }
        return show;
      },
        filterWasChanged(selectedFilter){
          this.filterSelected = selectedFilter;
        },
        showUserInfo(user){
          console.log('show');
          this.$refs.drawerUser.show(user);
        },
        deleteItem(user){
          let data = {
              title: "Eliminar usuario " + user.name,
              message: "El usuario no podrá ser recuperado. ¿Desea continuar con la operación?"
          }
          this.$refs.confirm.open(data, REQUEST_USER_DELETE, user._id);
        },
        toggleActivationItem(user){
          let data = {
              title: user.status ? "Desactivar usuario " + user.name : "Activar usuario " + user.name,
              message: "¿Desea continuar con la operación?"
          }
          this.$refs.confirm.open(data, REQUEST_USER_TOGGLE_ACTIVATE, user._id);
        },
        deleteItemUserConfirmed(item_id, isConfirmated){
          if(isConfirmated){
            this.$bus.$emit('toggleLoading', true);
            axios.post('users/delete',{user_id: item_id}).then(response => {
                console.log(response);
                this.$bus.$emit('toggleLoading', false);
                this.listUsers();
            }).catch(err => {

            });
          }
        },
        toggleActivationUserConfirmed(item_id, isConfirmated){
          if(isConfirmated){
            this.$bus.$emit('toggleLoading', true);
            axios.patch('users/toggleActivation',{user_id: item_id}).then(response => {
                this.$bus.$emit('toggleLoading', false);
                this.listUsers();
            }).catch(err => {

            });
          }
        },
        componentConfirmResult(isConfirmated, item_id, component){
          switch (component) {
                case REQUEST_USER_DELETE :
                  this.deleteItemUserConfirmed(item_id, isConfirmated);
                break;
                case REQUEST_USER_TOGGLE_ACTIVATE :
                  this.toggleActivationUserConfirmed(item_id, isConfirmated);
                break;
            }
        },
        searchUserData(search){
          this.currentSearch = search;
          this.listUsers();
        },
        listUsers(query){
            const search = this.currentSearch;
            this.$bus.$emit('toggleLoading', true);
            axios.get(`users/list?search=${search}`).then(response => {
                this.users = response.data;
                this.$bus.$emit('toggleLoading', false);
            }).catch(err => {

            });
        },
        initialize () {
            this.listUsers();
        },
    },
  }
</script>
<style scoped>
>>> .flex-empty div {
  width: 350px;
}
.card-item{
  min-width: 320px !important;
  border: none !important
  /*flex-basis: initial !important;
  max-width: initial !important;*/
}
#card-container, .data-containe{
  height: 55vh;
  overflow: auto;
  width: 100%;
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.data-container{
}
#custom-app-bar{
}
>>> .v-badge--dot .v-badge__badge{
  height: 12px !important;
  width: 12px !important;
  border-radius: 6px !important;
}

</style>