import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode';
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    user: null,
    route: null,
  },
  mutations: {
    setToken(state, token){
      state.token = token;
    },
    setUser(state, user){
      state.user = user;
    },
    setPath(state, route){
      state.route = route;
    }
  },
  actions: {
    savePath({commit}, path) {
      commit("setPath", path)
      localStorage.setItem('route', path)
    },
    saveToken({ commit }, token){//LLamada inmediatamente despues de iniciar sesion
      commit("setToken", token)
      commit("setUser", decode(token))

      const initialRoute = 'home';
      commit("setPath", initialRoute)

      localStorage.setItem('route', initialRoute)
      localStorage.setItem('token', token)
    },
    autoLogin({ commit }){
      const token = localStorage.getItem('token')
      const currentRoute = localStorage.getItem('route')
      if(token){
        commit("setToken", token)
        commit("setUser", decode(token))
      }
      router.push({name: currentRoute})
    },
    logout({ commit }){
      commit("setToken", null)
      commit("setUser", null)
      localStorage.removeItem('token')
      localStorage.removeItem('route')
      router.push({name: 'login'})
    }
  },
  modules: {
  }
})
