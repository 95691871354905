<template>
  <user-section />
</template>

<script>
// @ is an alias to /src
import UserSection from '@/components/Users.vue'

export default {
  components: {
    UserSection
  }
}
</script>
