<template>
    <v-navigation-drawer
      v-model="opened"
      height="100vh"
      right
      floating
      fixed
      temporary
      hide-overlay
      class="drawer-container"
    >
      <v-card flat v-if="user">
        <v-toolbar dense flat>
            <v-toolbar-title>Perfil de usuario</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="opened = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-list>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-avatar size="60">
                            <v-img
                            v-if="user.avatar"
                            :key="user.updatedAt"
                            :src="renderImage(user.avatar)"
                            ></v-img>
                            <img
                              v-else
                              :src="require('@/assets/app/img-default-colaborador.jpg')"
                              alt="image user"
                            />
                            <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img> -->
                        </v-avatar>
                    </v-list-item-avatar>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Nombre</v-list-item-title>
                        <v-list-item-subtitle>{{user.name || '-'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Email</v-list-item-title>
                        <v-list-item-subtitle>{{user.email || '-'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Télefono</v-list-item-title>
                        <v-list-item-subtitle>{{user.phone || '-'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Ciudad</v-list-item-title>
                        <v-list-item-subtitle>{{user.city || '-'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Estado</v-list-item-title>
                        <v-list-item-subtitle>{{user.state || '-'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>País</v-list-item-title>
                        <v-list-item-subtitle>{{user.country || '-'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Membresía</v-list-item-title>
                        <v-list-item-subtitle>{{user.membershipType && user.membershipType.name || '-'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-row
                    align="center"
                    justify="space-around"
                >
                <v-switch
                    @click.native="switchClicked()"
                    v-model="userLocked"
                    label="Usuario bloqueado"
                    ></v-switch>
            </v-row>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'UserInfoDrawer',
    data: () => ({
        opened: false,
        userLocked: false,
        user: null,
        publicPath: process.env.VUE_APP_URL
    }),
    methods:{
        renderImage(image){
          const imgPath = this.publicPath+'images/uploads/users/' + image;
          console.log(imgPath);
          return imgPath;
        },
        switchClicked(){
            this.$emit('toggleSwitch', this.user._id, true); 
        },
        show(user){
            this.opened = true;
            this.user = user;
            this.userLocked = !user.status;
        }
    }
}
</script>
<style scoped>
.drawer-container{
    width: 350px !important;
}
>>> .v-input--selection-controls .v-input__slot > .v-label{
    color: #08187D !important
}

</style>