<template>
<v-layout
          align-center
          justify-center
        >
            <v-card class="elevation-12 auth-form">
              <header-auth header-title="Regenerar contraseña"></header-auth>
              <v-card-text>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >
                  <v-text-field
                    v-if="!emailSended"
                    label="Email"
                    placeholder="Ingresa tu email"
                    v-model="email"
                    :rules="emailRules"
                    type="text"
                  >
                    <template v-slot:prepend-inner>
                      <unicon name="envelope" fill="#5A5A5A"></unicon>
                    </template>
                  </v-text-field>
                  <p class="text-center" v-if="emailSended">
                    <img
                      :src="require('@/assets/app/img-success-sign.svg')"
                      alt="Success recover"
                    >
                  </p>
                  <v-flex class="gray--text text-center" v-if="!emailFilled">
                    Enviaremos un código vía email para restaurar tu acceso.
                  </v-flex>
                  <v-flex class="black--text text-center" v-if="emailSended">
                    Correo enviado.<br>
                    Verifica tu bandeja de entrada o spam.<br>
                    Si en 10 minutos no llega, presiona reenviar.
                  </v-flex>
                  <v-flex class="red--text text-center" v-if="errorMessage">{{errorMessage}}</v-flex>
                </v-form>
              </v-card-text>
                <p class="text-center">
                <v-btn color="primary" :disabled="!emailValid || !valid" rounded :loading="processing" @click="recover">
                  {{!emailSended ? 'Enviar' : 'Reenviar correo'}}
                </v-btn>
                </p>
                <footer-auth />
            </v-card>
        </v-layout>
</template>
<script>
  import axios from 'axios';
import HeaderAuth from './components/HeaderAuth.vue';
import FooterAuth from './components/FooterAuth.vue';
  export default {
  components: { HeaderAuth, FooterAuth },
    data: () => ({
      email: '',
      emailSended: false,
      errorMessage: null,
      infoMessage: null,
      processing: false,
      valid: false,
      emailRules: [
        v => !!v || 'Campo requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail no válido',
      ],
    }),
    computed:{
      emailFilled(){
        return this.email != '';
      },
      emailValid(){
        return this.email != '';
      }
    },
    methods: {
      recover(){
          this.errorMessage = null;
          this.processing = true;
          axios.post('users/forgotPassword', {email: this.email})
          .then(response => {
            return response.data;
          }).then(data => {
            this.emailSended = true;
            this.processing = false;
          }).catch(e => {
            this.errorMessage = null;
            this.processing = false;
            if(e.response.status == 404){
              this.errorMessage = 'El correo no está registrado. Ponte en contacto con un administrador.';
            }else{
              this.errorMessage = 'Ocurrio un error con el servidor. Estamos resolviendo el problema.';
            }
          });
          
          
      }
    }
  }
</script>
<style scoped>
.disclaimer{
  padding: 10px;
}
</style>