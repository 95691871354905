<template>
    <div>
      <v-toolbar flat>
            <v-toolbar-title class="flex text-center">{{headerTitle}}</v-toolbar-title>
          </v-toolbar>
          <p class="text-center">
            <img
              :src="require('@/assets/app/logo-osf-login-web.png')"
              alt="App Logo"
            >
          </p>
    </div>
</template>
<script>
  export default {
    props: ["headerTitle"],
    name: 'HeaderAuth',
  }
</script>
<style scoped>
.v-toolbar__title{
  font-size: 14px !important;
}
</style>