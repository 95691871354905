import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import Unicon from 'vue-unicons'
import VueGoogleCharts from 'vue-google-charts'
import responsive from 'vue-responsive'

Vue.use(VueGoogleCharts)
Vue.use(responsive)

import { uniEdit, uniEnvelope, uniLock, uniEye, uniEyeSlash,
  uniRepeat, uniUserCircle, uniAngleDown, uniAngleUp, uniBrowser,
  uniSignout, uniApps, uniBag, uniTrafficLight, uniTransaction, uniMoneyWithdrawal,
  uniMoneyInsert, uniShop, uniUsersAlt, uniEllipsisH, uniEllipsisV, uniLockAlt, 
  uniKeySkeletonAlt, uniQuestionCircle, uniBookOpen, uniShieldCheck, uniSearch, 
  uniTrophy, uniUserCheck, uniUserTimes, uniCloudDownload} from 'vue-unicons/src/icons'
//import { UilRepeat } from '@iconscout/vue-unicons'

const bus = new Vue({});

Unicon.add([ uniEdit, uniEnvelope, uniLock, uniEye, uniEyeSlash,
  uniRepeat, uniUserCircle, uniAngleDown, uniAngleUp, uniBrowser,
  uniSignout, uniApps, uniBag, uniTrafficLight, uniTransaction, uniMoneyWithdrawal,
  uniMoneyInsert, uniShop, uniUsersAlt, uniEllipsisH, uniEllipsisV, uniLockAlt, 
  uniKeySkeletonAlt, uniQuestionCircle, uniBookOpen, uniShieldCheck, uniSearch, 
  uniTrophy, uniUserCheck, uniUserTimes, uniCloudDownload])
Vue.use(Unicon)

if (!localStorage.token) {
  // Si no existe, agrega el item vacio
  localStorage.setItem("token", "");
}

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT;
axios.defaults.headers = {
  token: localStorage.getItem("token")
}
Object.defineProperty(Vue.prototype, "$bus", {
  get() {
    return this.$root.bus;
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  data: {
    bus: bus
  },
  created() {
    console.log("Mounted application");
  }
}).$mount('#app')
