<template>
 <v-layout justify-center>
      <v-flex class="mt-12">
        <v-card flat>
          <!-- Push Contents In and Put Space Between Flex Items Using Grid List -->
          <v-container fluid grid-list-lg>
            <toolbar-dashboard title="Dashboard" />
            <v-subheader>Registro de usuarios</v-subheader>
            <v-layout row wrap>
              <!--  Create Cards Based on Objects in an Array -->
              <v-flex class="card-item" lg3 md3 xl3 sm6>
                <v-card flat>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="statistics">{{statistics.total}}</v-list-item-title>
                        <v-list-item-subtitle>Totales</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-flex>

              <v-flex class="card-item" lg3 md3 xl3 sm6>
                <v-card flat>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="statistics">{{statistics.free}}</v-list-item-title>
                        <v-list-item-subtitle>Free</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-flex>

              <v-flex class="card-item" lg3 md3 xl3 sm6>
                <v-card flat>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="statistics">{{statistics.premium}}</v-list-item-title>
                        <v-list-item-subtitle>Premium</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-flex>

              <v-flex class="card-item" lg3 md3 xl3 sm6>
                <v-card flat>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>$ 1,232,121</v-list-item-title>
                        <v-list-item-subtitle>Ingresos por membresías</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-flex>

              <v-flex class="card-item" lg12 md12 xl12 sm12>
                <GChart
                v-if="chartData"
                type="ColumnChart" style="min-height: 400px"
                :data="chartData"
                :options="chartOptions"
              />
              </v-flex>
              
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
</template>

<script>
  import axios from 'axios';
  import ToolbarDashboard from './Toolbars/ToolbarDashboard'
  export default {
    name: 'Dashboard',
    components: {
      ToolbarDashboard
    },
    mounted() {
      this.$store.dispatch('savePath', 'home');
      this.$bus.$emit('selectCurrentMenuOption',0);
    },
    data: () => ({
      statistics: null,
      chartData: [],
      chartOptions: {
        title : 'Registros por mes',
        width: '100%',
        height: 400,
        colors: ['#08187D', '#FF9F1C'],
        isStacked: true,
        //legend: { position: 'top', maxLines: 3 },
        chart: {
          title: 'Company Performance',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017',
        }
      }
    }),
    methods: {
       initialize () {
            this.getStatisticsYear();
            this.getStatisticsTotal();
        },
        getStatisticsYear(){
            this.$bus.$emit('toggleLoading', true);
            axios.get('users/statisticsYear').then(response => {
                this.chartData = response.data;
                this.$bus.$emit('toggleLoading', false);
            }).catch(err => { });
        },

        getStatisticsTotal(){
            this.$bus.$emit('toggleLoading', true);
            axios.get('users/statisticsTotals').then(response => {
                this.statistics = response.data;
                this.$bus.$emit('toggleLoading', false);
            }).catch(err => { });
        },
    },
    created () {
      this.initialize();
    },
  }
</script>

<style scoped>
/* .card-item{
  min-width: 250px !important;
  border: none !important
} */
#card-container, .data-containe{
  height: 55vh;
  overflow: auto;
  width: 100%;
  padding-right: 0 !important;
  margin-right: 0 !important;
}

.v-list-item__title{
  font-size: 30px !important;
}
.v-list-item__subtitle{
  font-size: 12px !important;
  padding-top: 5px;
  font-weight: bold;
}
.v-subheader{
  color: #08187D !important;
}
</style>
