<template>

    <v-layout align-center justify-center class="reseetpass">
        <skeleton-loader v-if="!checkedToken"/>
        <v-card class="elevation-12 auth-form" v-if="checkedToken">
            <header-auth header-title="Cambiar contraseña"></header-auth>
            <v-card-text>
                <v-form ref="form" v-model="valid">

                    <v-container class="code-fields" v-if="!checkedCode && !errorToken">
                        <v-row>
                            <v-col md="12">
                                <p class="label-code">Ingresa el código recibido</p>
                            </v-col>
                            <v-col md="4" >&nbsp; </v-col>
                            <v-col md="4">
                                <v-text-field v-model="code" placeholder="0000" :rules="[validCode]" maxlength="4" ></v-text-field>
                            </v-col>
                            <v-col md="4" > &nbsp; </v-col>
                        </v-row>
                    </v-container>

                    <v-text-field v-if="checkedCode && !passwordReseted"
                      :type="showedPassword ? 'text' : 'password'"
                      :rules="[rules.required,rules.min]"
                      label="Nueva contraseña"
                      v-model="password" >
                        <template v-slot:prepend-inner>
                            <unicon name="lock" fill="#5A5A5A"></unicon>
                        </template> 
                        <template v-slot:append>
                            <unicon name="eye" fill="#5A5A5A" v-if="!showedPassword" v-on:click="showedPassword=!showedPassword"></unicon>
                            <unicon name="eye-slash" fill="#5A5A5A" v-else v-on:click="showedPassword=!showedPassword"></unicon>
                        </template> 
                    </v-text-field>

                    <v-text-field v-if="checkedCode && !passwordReseted"
                      :rules="[rules.required,rules.min, passwordConfirmationRule]"
                      :type="showedPasswordConfirm ? 'text' : 'password'"
                      label="Repetir contraseña"
                      v-model="passwordConfirm" >
                        <template v-slot:prepend-inner>
                            <unicon name="repeat" fill="#5A5A5A"></unicon>
                        </template> 
                        <template v-slot:append>
                            <unicon name="eye" fill="#5A5A5A" v-if="!showedPasswordConfirm" v-on:click="showedPasswordConfirm=!showedPasswordConfirm"></unicon>
                            <unicon name="eye-slash" fill="#5A5A5A" v-else v-on:click="showedPasswordConfirm=!showedPasswordConfirm"></unicon>
                        </template>
                    </v-text-field>

                    <p class="text-center" v-if="passwordReseted">
                        <img :src="require('@/assets/app/img-success-sign.svg')" alt="Success recover" >
                    </p>
                    <v-flex class="black--text text-center" v-if="passwordReseted">
                        Contraseña cambiada exitosamente.
                    </v-flex>
                    <v-flex class="red--text text-center" v-if="errorMessage">{{errorMessage}}</v-flex>
                </v-form>
            </v-card-text>

            <p class="text-center">
              <!-- recuarda cambiarlo por !passwordReseted -->
                <v-btn v-if="!passwordReseted" color="primary" :disabled="!valid || !validCode" rounded :loading="processing" @click="processValidation">
                    {{!checkedCode ? 'Continuar' : 'Cambiar contraseña'}}
                </v-btn>

                <span v-else>
                    <v-btn v-responsive.lg.xl
                     href="/login" color="primary" rounded>Ir a login</v-btn>
                     
                    <span  v-responsive.md class="text-center disclaimer">
                      Ahora puedes ingresar desde la app con tu nueva contraseña.
                    </span>

                    <span  v-responsive.sm.xs class="text-center disclaimer">
                      Ahora puedes ingresar desde la app con tu nueva contraseña.
                    </span>
                </span>
            </p>


            <footer-auth />
        </v-card>
    </v-layout>

</template>


<script>
  import axios from 'axios';
  import HeaderAuth from './components/HeaderAuth.vue';
  import FooterAuth from './components/FooterAuth.vue';
  import SkeletonLoader from './components/SkeletonLoader.vue';
  import responsive from 'vue-responsive'
  
 
  export default {
  components: { HeaderAuth, FooterAuth, SkeletonLoader },
    data: () => ({
      code: '',
      password: '',
      passwordConfirm: '',
      checkedToken: false,
      checkedCode: false,
      errorToken: false,
      passwordReseted: false,
      showedPassword: false,
      showedPasswordConfirm: false,
      errorMessage: null,
      infoMessage: null,
      processing: false,
      loading: true,
      transition: "scale-transition",
      valid: false,
      rules: {
          required: value => !!value || 'Campo requerido',
          min: v => v.length >= 8 || 'Mínimo 8 caracteres',
        },
    }),
    computed:{
      passwordFilled(){
        return this.password != '';
      },
      validCode(){
        return this.code != '' && this.code.length == 4;
      },

      passwordConfirmationRule() {
      return this.password === this.passwordConfirm || 'La contraseña no coincide'
      }
    },
    methods: {
      checkToken(){
        this.checkedToken = false;
        const token = this.$route.params.token;
        axios.post('users/validateResetToken', {token})
        .then(response => {
          this.checkedToken = true;
          this.errorToken = false;
          return response.data;
        }).then(data => {
          console.log(data);
        }).catch(e => {
          this.checkedToken = true;
          this.errorToken = true;
          if(e.response.status == 404){
            this.errorMessage = 'Url de reseteo de contraseña es inválido, ha expirado o ya fue utilizado anteriormente.';
          }else{
            this.errorMessage = 'Ocurrio un error con el servidor. Estamos resolviendo el problema.';
          }
          //this.$router.push({name:'login'})
        });
      },
      processValidation(){
        this.errorMessage = null;
        this.processing = true;
        if(!this.checkedCode){
          this.checkCode();
        }else{
          this.processChangePassword();
        }
      },
      checkCode(){
          axios.post('users/validateResetCode', {code: this.code})
          .then(response => {
            return response.data;
          }).then(data => {
            this.checkedCode = true;
            this.processing = false;
            this.valid = false;
          }).catch(e => {
            this.errorMessage = null;
            this.processing = false;
            if(e.response.status == 404){
              this.errorMessage = 'Código inválido. Verifícalo o genera uno nuevo dando clic en regenerar contraseña.';
            }else{
              this.errorMessage = 'Ocurrio un error con el servidor. Estamos resolviendo el problema.';
            }
          });
      },
      processChangePassword(){
        const token = this.$route.params.token;
            axios.post('users/resetPassword', {
            code: this.code,
            token,
            password: this.password})
          .then(response => {
            return response.data;
          }).then(data => {
            this.passwordReseted = true;
            this.processing = false;
          }).catch(e => {
            this.errorMessage = null;
            this.processing = false;
            if(e.response.status == 404){
              this.errorMessage = 'Token o código inválido. Verifícalo o genera uno nuevo dando clic en regenerar contraseña.';
            }else{
              this.errorMessage = 'Ocurrio un error con el servidor. Estamos resolviendo el problema.';
            }
          });
      }
    },
    mounted(){
      this.checkToken();
    }
  }
</script>

<style scoped>
.label-code{
  text-align: center;
}
.code-fields input{
  text-align: center !important;
}
.code-fields .col{
  padding: 0 !important;
}
.code-fields .col p{
  margin: 0 !important;
  color: #000000;
}

.reseetpass{
  margin: 0 !important;
  padding: 0 !important;
}
</style>