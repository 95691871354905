import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Recover from '../views/Recover.vue'
import Reset from '../views/Reset.vue'
import Users from '../views/Users.vue'
//import Users from './../components/Users'
//import Login from './../components/Login/Login'
//import RecoverPassword from './../components/Login/RecoverPassword'
//import ResetPassword from './../components/Login/ResetPassword'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      admin: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta:{
      free: true
    }
  },
  {
    path: '/recoverPassword',
    name: 'recover',
    component: Recover,
    meta:{
      free: true
    }
  },
  {
    path: '/resetPassword/:token',
    name: 'resetPassword',
    component: Reset,
    meta:{
      free: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      admin: true
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //console.log(store.state.user.role);
  if(to.matched.some(record => record.meta.middlewareCheck)){
    next()
  }else if(to.matched.some(record => record.meta.free)){
    next()
  }else if(store.state.user && store.state.user.role == 'admin'){
    if(to.matched.some(record => record.meta.admin)){
      next()
    }
  }else{
    next({name: 'login'})
  }
});

/*
router.afterEach((to, from, next) => {
  console.log(to);
});*/

export default router
