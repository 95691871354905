<template>
    <v-container>
    <v-row justify="center">
        <v-col class="mb-12" cols="12" md="4">
            <v-skeleton-loader
            class="mx-auto"
            max-width="400"
            type="card"
            ></v-skeleton-loader>
        </v-col>
    </v-row>
    </v-container>
</template>
<script>
  export default {
    name: 'SkeletonLoader',
  }
</script>
<style scoped>
</style>