<template>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      permanent
      color="drawer"
    >

     <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            <v-img
              :src="require('@/assets/app/logo-osf-panel-web.png')"
              contain
              height="50"
            />
          </v-list-item-title>
          <v-list-item-subtitle>
            &nbsp;
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list dense id="mainMenu">
        <v-list-item-group
          v-model="selectedItem"
          :mandatory="true"
          v-if="isAdmin"
        >
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="selectMod(i)"
          >
            <v-list-item-icon>
              <unicon v-if="selectedItem == i" :name="item.icon" fill="#FFFFFF"></unicon>
              <unicon v-else :name="item.icon" fill="#616C76"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
  data: () => ({
      drawer: null,
      selectedItem: 0,
      items: [
        { text: 'Dashboard', icon: 'apps',  },
        { text: 'Usuarios', icon: 'users-alt' },
        //{ text: 'Torneos', icon: 'trophy' },
      ],
    }),
    computed:{
      isMaster(){
        return this.$store.state.user && this.$store.state.user.role == 'master';
      },
      isAdmin(){
        return this.$store.state.user && this.$store.state.user.role == 'admin';
      },
      isGrocer(){
        return this.$store.state.user && this.$store.state.user.role == 'grocer';
      }
    },
    methods: {
      selectMenuOption(selected){
        this.selectedItem = selected;
      },
      selectMod(index) {
        this.selectedItem = index;
        switch(index){
          case 0:
            this.$router.push({ name: "home" });
          break;
          case 1:
            this.$router.push({ name: "users" });
          break;
          default:
          // statements_def
          break;
        }
      }
    },
    created() {
      this.$bus.$on('selectCurrentMenuOption', this.selectMenuOption);
    }
};
</script>
<style scoped>
#mainMenu .v-list-item{
  color: #616C76 !important;
}
#mainMenu .v-list-item.v-item--active{
  color: #FFFFFF !important;
  background: #08187D;
}
</style>