<template>
  <v-layout align-center justify-center>
    <recover-password />
  </v-layout>
</template>

<script>
// @ is an alias to /src
import RecoverPassword from '@/components/Login/RecoverPassword.vue'

export default {
  components: {
    RecoverPassword
  }
}
</script>
