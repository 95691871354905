<template>
  <v-app :class="!loggedIn ? 'non-auth':''">
    <v-overlay v-if="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <app-drawer v-if="loggedIn"/>
    <app-top-bar v-if="loggedIn"/>

    <v-main>
      <v-container fill-height fluid>
        <v-fade-transition mode="out-in">
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
      <app-footer/>
    </v-main>
  </v-app>
</template>

<script>
import AppDrawer from '@/components/Layout/AppDrawer.vue' 
import AppTopBar from '@/components/Layout/AppTopBar.vue'
import AppFooter from '@/components/Layout/AppFooter.vue'
export default {
  name: 'App',
  components: {
    AppDrawer,
    AppTopBar,
    AppFooter
  },
  data: () => ({
    loading: false,
    }),
    methods: {
      loadGlobalListeners () {
            this.$bus.$off('toggleLoading');

            this.$bus.$on('toggleLoading', (isLoading) => {
              this.loading = isLoading;
            });
      },
    },
    computed:{
      loggedIn(){
        return this.$store.state.user;
      },
    },
    beforeCreate() {
      //Check login
      this.$store.dispatch('autoLogin');
    },
    created(){
      this.loadGlobalListeners();
    }
};
</script>
<style scoped>
@import './assets/styles/application.css';
.non-auth{
  background: url('./assets/app/bg-login.png') no-repeat center center fixed !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}
</style>