<template>
    <v-app-bar
                      color="white"
                      dense
                      flat
                      class="section-toolbar"
                      id="custom-app-bar"
              >
                <v-toolbar-title>{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>

                 <download-excel
                    :fetch = "fetchData"
                    :fields="json_fields"
                    type="csv"
                    :name="xlsFilename"
                >
                    <v-btn
                        color="primary"
                        text
                        dark
                        >
                        Descargar listado
                        <unicon name="cloud-download" fill="#08187D"></unicon>
                    </v-btn>
                </download-excel>

                <v-select
                    hide-details
                    dense
                    class="select-filter"
                    v-model="selected_filter"
                    :items="users_filter_items"
                    item-text="text"
                    item-value="status"
                    @change="selectFilter"
                ></v-select>

                <v-text-field
                  class="search-field"
                  v-model="searchValue"
                  hide-details
                  :placeholder="placeholderSearch"
                  clearable
                  outlined
                  dense
                  @click:clear="clearSearch"
                  @keydown.enter="searchChanged"
                >
                <template v-slot:append>
                    <unicon name="search" fill="#5A5A5A" v-on:click="searchChanged"></unicon>
                </template>
            </v-text-field>
              </v-app-bar>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
export default {
    name: "ToolbarSection",
    props: {
        title: String,
        placeholderSearch: String
    },
    data: () => ({
        searchValue: '',
        selected_filter: -1,
        users_filter_items: [
            {status: -1, text: 'Todos los usuarios'},
            {status: 1, text: 'Usuarios activos'},
            {status: 0, text: 'Usuarios bloqueados'},
            {status: 2, text: 'Membresia: Free'},
            {status: 3, text: 'Membresia: Premium'}
        ],
         json_fields: {
                "Nombre": "name",
                "Email": "email",
                Rol: "role.name",
                Membresia: "membershipType.name",
                "Ciudad": {
                    field: "city",
                    callback: (city) => {
                    return city ? city : '-';
                    },
                },
                "Teléfono": {
                    field: "phone",
                    callback: (phone) => {
                    return phone ? phone : '-';
                    },
                },
                "Estatus": {
                    field: "status",
                    callback: (status) => {
                    return status ? 'Activo' : 'Bloqueado';
                    },
                },
            },
            json_meta: [
                [
                    {
                    key: "charset",
                    value: "utf-8",
                    },
                ],
            ],
    }),
    computed:{
        xlsFilename(){
            const dateDay = 'Usuarios OSF';
            return dateDay+'.xls';
        },
    },
    methods: {
         async fetchData(){
            const search = '';
            this.$bus.$emit('toggleLoading', true);
            const response = await axios.get(`users/list?search=${search}`);
            this.$bus.$emit('toggleLoading', false);
            const json = [];
            response.data.map(user => {
                json.push({
                        name: user.name,
                        email: user.email,
                        role: user.role,
                        membershipType: user.membershipType,
                        city: user.city,
                        phone: user.phone,
                        status: user.status
                });
            });
            return json;
            
         },
        selectFilter(value){
            this.$emit("filterItemChanged", value);
        },
        clearSearch(){
            this.searchValue = '';
            this.searchChanged();
        },
        searchChanged(){
            this.$emit("searchUser", this.searchValue);
        }
    }
}
</script>
<style scoped>

>>> .v-btn__content{
    text-transform: capitalize !important;
    font-size: 15px !important;
    letter-spacing: 0 !important;
    font-weight: normal !important;
}
.section-toolbar .v-btn{
    margin-right: 50px !important;
}
.section-toolbar .v-btn .unicon{
    margin-left: 10px !important;
}
</style>