<template>
    <div class="d-flex flex-column justify-space-between align-center">
        <v-img
            width="300"
            height="300"
            :src="require('@/assets/app/img-default-notFound.png')"
        ></v-img>
        <div class="title mb-1 not-found-container">
            No se encontraron resultados
        </div>
    </div>
</template>
<script>
  export default {
    name: 'NoResults',
    data: () => ({
    }),
  }
</script>