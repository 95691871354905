<template>
  <v-layout align-center justify-center>
    <login />
  </v-layout>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login/Login.vue'

export default {
  components: {
    Login
  }
}
</script>
