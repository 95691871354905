<template>
    <v-app-bar
                      color="white"
                      dense
                      flat
                      class="section-toolbar"
                      id="custom-app-bar"
              >
                <v-toolbar-title>{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-app-bar>
</template>
<script>
import Vue from 'vue';
export default {
    name: "ToolbarDashboard",
    props: {
        title: String,
        placeholderSearch: String
    },
    data: () => ({
        
    }),
    methods: {
    }
}
</script>
<style scoped>
.v-toolbar__title{
    font-weight: bold;
}
</style>